<template>
  <div id="container">
    <h2 id="header">Tuku Terms Of Service</h2>
    <body id="body">
      <div id="content">
        <label id="summaryTitle"> <br />SUMMARY </label>

        <p id="summary">
          Thank you for using Tuku! We respect your concerns about privacy and appreciate
          your trust and confidence in us.
          <br />Here is a summary of the information contained in this terms of service
          ("Terms of service"). This summary is to help you navigate the Terms of service and
          it is not a substitute for reading everything! You can use the hyperlinks below
          to jump directly to particular sections in the Terms of service.
        </p>

        <p id="summaryTitle"><br />WHAT INFORMATION DO YOU NEED TO PROVIDE TUKU?</p>

        <p id="summary">
          When you register for a Tuku account, we will need your phone number and a
          full name. You can further refine and populate your profile with additional
          information. If you use certain functions available within Tuku (such as
          sending photos), we will process your information to provide these functions
        </p>

        <p id="summaryTitle"><br />HOW DO WE USE YOUR INFORMATION?</p>

        <p id="summary">
          We use your information to provide Tuku services to you, allow you to
          communicate with other users, allow you to find nearby users, and to improve and
          support your Tuku experience.
        </p>

        <p id="summaryTitle"><br />WHO DO WE SHARE YOUR DATA WITH?</p>

        <p id="summary">
          We do not share your information with third parties, except where we need to in
          order to provide the service (e.g., SMS, payment processing, location and
          mapping service providers) or if we are instructed to by a court, authority or
          compelled by law. We use these third party services solely to process or store
          your information for the purposes described in this Terms of service. Any third
          party with whom we share user data is required to provide the same or equal
          protection of user data as stated in this Terms of service
        </p>

        <p id="summaryTitle"><br />WHERE DO WE PROCESS YOUR DATA?</p>

        <p id="summary">
          Our servers are located in United Kingdom. We also have support, engineering and
          other teams that support the provision of Tuku services to you, located around
          the world (including China and the United States Of America), who will have
          access to your information. Rigorous internal control measures are in place to
          strictly limit access to your data by designated personnel.
        </p>

        <p id="summaryTitle"><br />HOW LONG DO WE KEEP HOLD OF YOUR DATA?</p>

        <p id="summary">
          How long we retain information for depends on the type of information – for
          example, log-in data is retained for up to 90 days from the date the data is
          collected. We do not retain your information for longer than the time period
          prescribed by law.
        </p>

        <p id="summaryTitle"><br />HOW CAN YOU EXERCISE YOUR RIGHTS OVER YOUR DATA?</p>

        <p id="summary">
          Depending on where you live, you may have special rights over your data and how
          we can use it. These include how you can access the data, erase the data,
          restrict how your data can be used, object to our use, and get a copy of your
          information
        </p>

        <p id="summaryTitle">
          <br />
          INAPPROPRIATE CONTENT AND MISCONDUCT
        </p>

        <p id="summary">
          Tuku does not tolerate inappropriate content or behavior on our Services.

We are committed to maintaining a positive and respectful community, and we do not tolerate any inappropriate content or misconduct, whether on or off of the Services. We encourage you to report any inappropriate Member Content or misconduct by other users. You can report a user directly through the "Report User" link on a user's profile or in the messaging experience. You may also contact Customer Support. 

As set forth in our Terms of service, we may share data between our affiliates for the safety and security of our users and may take necessary actions if we believe you have violated these Term, and/or preventing you from creating new accounts. You understand and agree that we may not share information with you regarding your account if doing so would potentially impair the safety or privacy of our other users.
        </p>

        <p id="summary">
          <br />Welcome to Tuku!
          <br />
          <br />When you use the Tuku mobile app or the websites www.Tukuapp.com
          (together, "Tuku"), certain of your Personal Information (defined below) will
          be collected and processed by us. This Terms of service explains the when, how and
          why when it comes to processing of your Personal Information in connection with
          Tuku, and sets out your choices and rights in relation to that Personal
          Information. In this Terms of service, "we", "our" or "us" refers to: if you are a
          Tuku user in Ghana: Digate Technology Company Ltd. . The above entity is also
          the data controller for users located in the abovementioned jurisdiction. Any
          capitalised terms used in this Terms of service have the same meaning as the
          equivalent defined terms in the Tuku Terms of Service, unless they are defined
          otherwise in this Terms of service.
          <br />
          <br />CONTACT
          <br />
          <br />If you have any questions or complaints regarding this Terms of service or
          the use of your Personal Information, please contact our Data Protection Officer
          via email at data@Tuku.com(Attention: Data Protection Officer, Legal
          Department). Please note that if you are a resident in Ghana, you have the right
          to lodge a complaint with your country's data protection authority. By using
          Tuku you agree to the processing of your Personal Information in accordance
          with this Terms of service. If you do not agree to the processing of your Personal
          Information in the way this Terms of service describes, please do not provide your
          Personal Information when requested and stop using Tuku.
        </p>

        <p id="summaryTitle"><br />2. HOW DO WE USE YOUR INFORMATION?</p>

        <p id="summary">
          This section provides more detail on the types of Personal Information we
          collect from you, purpose of processing, and retention periods for each type of
          Personal Information. For users resident in a Relevant Jurisdiction, it also
          identifies the legal basis under which we process your data. For the purposes of
          this Terms of service, a "Relevant Jurisdiction" includes Ghana. We do not retain
          Personal Information for longer than is necessary unless we are required to do
          so under applicable laws. For further details on how long we keep your data,
          please refer to the retention periods set out below. If we are required to
          retain your Personal Information beyond the retention periods set out below, for
          example to comply with applicable laws, we will store it separately from other
          types of Personal Information. Aggregated registration data (which does not
          contain Personal Information) is retained for spam prevention and system
          security purposes.
        </p>

        <p id="summaryTitle"><br />3. WHO DO WE SHARE YOUR DATA WITH?</p>

        <p id="summary">
          We will not transfer your Personal Information to third parties except as
          specified below, or where you consent to such transfer. Only where necessary
          will we share your information with selected recipients, including: related
          group companies. We share your Personal Information within our group of
          companies, and these related group companies may only use your Personal
          Information in accordance with this Terms of service. The purpose is to enable us
          provide Tuku services to you, assist us in carrying out the purposes set out
          under the "How do we use your information?" section above, and carrying out our
          obligations and enforcing our rights under the Tuku Terms of Service or this
          Terms of service; service providers. We engage service providers to supply
          services to support or improve Tuku. These include SMS, payment card processing
          and verification, location and mapping, customer service, and content delivery
          service providers. These service providers are required to provide the same or
          equal protection of user data as stated in this Terms of service and are
          prohibited from retaining, using, or disclosing your information except as
          necessary to provide services to us;
        </p>

        <p id="summaryTitle"><br />4. SECURITY OF YOUR PERSONAL INFORMATION?</p>

        <p id="summary">
          We are committed to maintaining the privacy and integrity of your Personal
          Information no matter where it is stored or accessed. We protect your Personal
          Information through the use of information security and access policies that
          limit unauthorised access to our systems, and technological protection measures
          such as encryption that ensure confidentiality of your Personal Information
          during transmission and in storage. We do not permanently retain chat data
          between you and other users on our servers. Although we will implement and
          maintain reasonable measures to protect your Personal Information, please note
          that as any transmission of information via the internet is never completely
          secure.
        </p>

        <p id="summaryTitle"><br />5. YOUR RIGHTS</p>

        <p id="summary">
          This Section 5 (Your Rights) only applies to users located in a Relevant
          Jurisdiction, unless otherwise specified. If you are a user located in Ghana,
          the terms set out under the name of your jurisdiction in this page apply to you
          in addition to the terms set out in our Terms of service. Please note that in
          order to protect your information, we will need to verify your identity before
          responding to any request to exercise your rights ("DSR Request"). For users in
          a Relevant Jurisdiction, we will respond to your DSR Request without undue delay
          and in any event within one month (although this may be extended by a further
          two months in certain circumstances, such as where the request involves
          substantial volumes of information or is otherwise complex).
          <br />
          <br />Access & Correction <br />This section also applies to users resident in
          Argentina, Canada, India and Russia. You have the right to request access to
          your Personal Information we hold about you, how we use it, and who we share it
          with. We may not be able to provide you with certain Personal Information if
          providing it to you would interfere with another person's rights (e.g. where
          providing the Personal Information we hold about you would reveal information
          about another person). You also have the right to correct that information if it
          is inaccurate or incomplete. You can access and correct your Personal
          Information by logging into your Tuku account at any time. For example, you can
          delete certain Location Data via your device settings or the "Clear Location"
          option within Tuku. If you want us to correct your Personal Information that
          you are unable to correct using your account, please complete the request form
          here. Where we agree to correct your Personal Information, we will use
          reasonable steps to inform any third party to whom we have disclosed the
          relevant Personal Information so that they can rectify the Personal Information
          too.
          <br />
          <br />Erasure <br />This section also applies to users resident in Argentina,
          Canada, India and Russia. You can delete your account, or remove certain
          Personal Information, by logging into your Tuku account and following the
          account deletion instructions here. Subsequent to account deletion, aggregated
          registration data is retained for spam prevention and systems security purposes.
          Note that we will not be able to delete your account if you have a positive
          balance in your Weixin Pay or Tuku Pay account or are the administrator of an
          Official Account or Mini Program. If there is any other Personal Information you
          believe we process that you would like us to erase, please complete the request
          form here. You may request that we erase the Personal Information we hold about
          you where: you believe that it is no longer necessary for us to hold your
          Personal Information; we obtained your consent to process the Personal
          Information and you withdraw that consent (and we have no other valid legal
          grounds for processing the Personal Information); you believe we are unlawfully
          processing your Personal Information; or you are or were under the age of 16 (or
          such applicable age of majority under relevant local laws) when we collected the
          Personal Information and we can verify this. You may exercise your right to
          restrict our processing your Personal Information (as described below) whilst we
          consider a request to erase your data. We may retain the Personal Information if
          there are valid legal grounds for us to do so (e.g., for the defence of legal
          claims, freedom of expression or some other legal obligation) but we will let
          you know if that is the case. Where you have requested that we erase your
          Personal Information that we have made public and there are valid legal grounds
          for erasure, we will use reasonable steps to inform any third party to whom we
          have disclosed the relevant Personal Information so that they can erase it too.
          <br />
          <br />Restriction of Processing to Storage Only <br />This section also applies
          to users resident in Russia. You have a right to require us to stop processing
          your Personal Information other than for storage purposes in certain
          circumstances. However, we may process it if there are other valid legal grounds
          for us to do so (e.g., for the defence of legal claims or for another's
          protection). Where we agree to stop processing the Personal Information, we will
          use reasonable steps to inform any third party to whom we have disclosed the
          relevant Personal Information so that they can stop processing it too. You may
          request we stop processing and just store the Personal Information we hold about
          you where: you believe the Personal Information is not accurate (for the period
          it takes for us to verify whether it is accurate); we wish to erase the Personal
          Information as the processing is now unlawful (but you want us to retain the
          Personal Information and just store it instead); or we wish to erase the
          Personal Information as it is no longer necessary for our purposes (but you
          require it to be stored for the establishment, exercise or defence of legal
          claims).
          <br />
          <br />Portability <br />You have the right to receive a copy of certain of your
          Personal Information in a structured, commonly used and machine-readable format.
          This comprises any Personal Information we process on the basis of your consent
          (e.g., Shared Information and content saved to Favorites) or pursuant to our
          contract with you (e.g., profile data), as described in the section "How do we
          use your information?". You can exercise your right to export your data by
          following the instructions here. You also have the right to request that we
          transfer that Personal Information to another party. If you wish for us to
          transfer the Personal Information to a third party, please ensure you detail
          that party. Note that we can only make such transfers where it is technically
          feasible. We are not responsible for the security of the Personal Information or
          its processing once received by the third party. Objection This section also
          applies to users resident in Russia. You may object to our use of your Personal
          Information that we use on the basis of our legitimate interests (e.g., when we
          use your Personal Information to ensure account security). If you object to such
          processing, please complete this request form. To the extent provided by
          applicable laws and regulations, you may withdraw any consent you previously
          provided to us by following the instructions set out here.
        </p>

        <br />
        <br />
        <br />
      </div>
    </body>
  </div>
</template>

<script>
export default {
  name: "privacy",
  data() {
    return {};
  },
};
</script>

<style scoped>
#header {
  background-color: white;
  font-family: "Roboto";
  font-weight: 700;
  padding-top: 60px;
}

#body {
  background-color: white;
  font-family: "Roboto";
  font-weight: 400;
  margin-top: 10px;
  padding-right: 50px;
  padding-left: 50px;
}

#container {
  background-color: white;
}

#content {
  background-color: white;
  width: 96%;
  margin-right: auto;
  margin-left: auto;
}

#summary {
  text-align: left;
  font-family: "Roboto";
  font-weight: 400;
  font-size: 15px;
}

#summaryTitle {
  display: block;
  text-align: left;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 16px;
}
</style>
